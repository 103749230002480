import { Injectable } from '@angular/core';
import { Media, MediaAction } from '@app/model';
import { Observable} from 'rxjs';
import { HttpBaseService } from './http-base.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private readonly url = '/medias';

  private readonly iconVideo = 'bi bi-file-earmark-play-fill';
  private readonly iconImage = 'bi bi-file-earmark-image-fill';
  private readonly iconSound = 'bi bi-file-earmark-music-fill';

  allowedMimeTypes = [
          {
            icon: 'bi bi-file-earmark-word-fill',
            mimetype: 'application/msword',
            extension: 'doc'
          },
          {
            icon: 'bi bi-file-pdf-fill',
            mimetype: 'application/pdf',
            extension: 'pdf'
          },
          {
            icon: 'bi bi-file-pdf-fill',
            mimetype: 'ChalkBoxReader.Document',
            extension: 'pdf'
          },
          {
            icon: 'bi bi-file-earmark-excel-fill',
            mimetype: 'application/vnd.ms-excel',
            extension: 'xls'
          },
          {
            icon: 'bi bi-file-earmark-ppt-fill',
            mimetype: 'application/vnd.ms-powerpoint',
            extension: 'ppt'
          },
          {
            icon: 'bi bi-file-earmark-ppt-fill',
            mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            extension: 'pptx'
          },
          {
            icon: 'bi bi-file-earmark-excel-fill',
            mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            extension: 'xlsx'
          },
          {
            icon: 'bi bi-file-earmark-word-fill',
            mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            extension: 'docx'
          },
          {
            icon: this.iconImage,
            mimetype: 'image/bmp',
            extension: 'bmp'
          },
          {
            icon: this.iconImage,
            mimetype: 'image/gif',
            extension: 'gif'
          },
          {
            icon: this.iconImage,
            mimetype: 'image/jpeg',
            extension: 'jpg'
          },
          {
            icon: this.iconImage,
            mimetype: 'image/png',
            extension: 'png'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/mp4',
            extension: 'mp4'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/3gpp2',
            extension: '3g2'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/3gpp',
            extension: '3gp'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/avi',
            extension: 'avi'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/quicktime',
            extension: 'mov'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/mpeg',
            extension: 'mpg'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/ogg',
            extension: 'ogv'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/webm',
            extension: 'webm'
          },
          {
            icon: this.iconVideo,
            mimetype: 'video/x-ms-wmv',
            extension: 'wmv'
          },
          {
            icon: this.iconSound,
            mimetype: 'audio/mpeg',
            extension: 'mp3'
          },
          {
            icon: this.iconSound,
            mimetype: 'audio/ogg',
            extension: 'ogg'
          },
          {
            icon: this.iconSound,
            mimetype: 'audio/vnd.wav',
            extension: 'wav'
          },
          {
            icon: 'bi bi-link',
            mimetype: 'iclasse/route',
            extension: ''
          },
          {
            icon: this.iconVideo,
            mimetype: 'iclasse/ubicast',
            extension: ''
          }
      ];

  constructor(
    private readonly settings: SettingsService,
    private readonly http: HttpBaseService
  ) {}

  public upload(formData: FormData): Observable<any> {
    return this.http.upload<any>(`${this.url}/upload`, formData);
  }
  public getAllowedMimeTypes(): string[] {
    return this.allowedMimeTypes.map(t => t.mimetype);
  }
  public getIcon(mimetype: string): any {
    return this.allowedMimeTypes.find(t => t.mimetype === mimetype).icon;
  }

  public detectMediaActionToHandle(mediaUrl: string): MediaAction {
    const mediaAction = new MediaAction();
    mediaAction.url = mediaUrl;
    if (mediaUrl) {
      if (mediaUrl.startsWith(this.settings.mediaPath)) {
        const validateMedia = /\/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})\/.*/;
        const matches = mediaUrl.match(validateMedia);
        if (matches.length > 1) {
          const extension = /(?:\.([^.]+))?$/.exec(mediaUrl.toLowerCase())[1];
          if (extension) {
            mediaAction.pdf = extension === 'pdf';
            mediaAction.image = this.allowedMimeTypes.filter(t => t.icon === this.iconImage).map(t => t.extension).indexOf(extension) >= 0;
            mediaAction.sound = this.allowedMimeTypes.filter(t => t.icon === this.iconSound).map(t => t.extension).indexOf(extension) >= 0;
          }
        }
      }
      if (mediaUrl.startsWith(this.settings.mediaServer)) {
        mediaAction.video = true;
      }
    }
    return mediaAction;
  }
}
