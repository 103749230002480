import { Component, OnInit, ViewChild } from '@angular/core';
import { Booklet, Filter, Criteria, MonitoredStudent, Observation, EvaluationScale, Category, Progression, FilterCategory } from '@app/model';
import { BookletService, ToastService, ReferenceDataService } from '@app/core/service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import * as Chart from 'chart.js';
import { EvaluationComponent } from '../../../shared/components/evaluation/evaluation.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TagsTogglerComponent } from '../../../shared/components/tags-toggler/tags-toggler.component';
import { StackedProgressBarComponent } from '../../../shared/components/stacked-progress-bar/stacked-progress-bar.component';
import { CriteriaComponent } from '../criteria/criteria.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ActionMenuComponent } from '../../../shared/components/action-menu/action-menu.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';
import { BookletObservationComponent } from '../booklet-observation/booklet-observation.component';

@Component({
    selector: 'nemo-booklet-detail',
    templateUrl: './booklet-detail.component.html',
    styleUrls: ['./booklet-detail.component.scss'],
    standalone: true,
    imports: [BasepageComponent, NgIf, NgClass, CardComponent, RouterLink, ActionMenuComponent, ClipboardModule, NgFor, CriteriaComponent, StackedProgressBarComponent, TagsTogglerComponent, NgbTooltip, EvaluationComponent, BookletObservationComponent]
})
export class BookletDetailComponent implements OnInit {
  @ViewChild('lineChart', { static: false }) private readonly chartRef;
  chart: any;

  public booklet: Booklet;
  public rpn = false;
  public filters: Filter[][]=[];
  public disciplines: FilterCategory[]=[];
  public annees: FilterCategory[]=[];
  public axesThematiques: Filter[]=[];
  public filteredCriterias: Criteria[];
  public observations: Observation[];
  public scale: EvaluationScale;
  public progression: Progression;

  constructor(
    public router: Router,
    private readonly service: BookletService,
    private readonly route: ActivatedRoute,
    private readonly referenceDataService: ReferenceDataService,
    private readonly toastService: ToastService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.service.get(params['id'] ?? params['secret']).subscribe({
        next: booklet => {
          this.scale = this.service.getEvaluationScale(booklet.optionsProfessionnelles);
          this.booklet = booklet;
          this.disciplines = this.referenceDataService.getCategories().filter(c=>booklet.categories.includes(c.key) && c.group=="Discipline").sort((a, b) => a.ord - b.ord).map(d=>{
            return {
              category:d,
              selected:false,
              value:d.value
            }});
          this.annees = this.referenceDataService.getCategories().filter(c=>booklet.categories.includes(c.key) && c.group=="Année").sort((a, b) => a.ord - b.ord).map(d=>{
            return {
              category:d,
              selected:false,
              value:d.value
            }});
          this.filteredCriterias = booklet.criterias;
          this.rpn = this.booklet.rpn;
          const tags = [];

          [0, 1, 2, 3, 4, 5, 6, 7].forEach(tidx => {
            const catTags = [].concat([], booklet.criterias
              .map(l => l.tags[tidx])).filter(Boolean).filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            });
            if (catTags.length > 0) {
              tags.push(catTags.map(t => new Filter(t)));
            }
          });
          this.axesThematiques = [...new Set(booklet.criterias.map(c=>c.axesThematiques).filter(c=>c!=null).flat())].map(a=>new Filter(a));
        // tags.push(this.axesThematiques);
          this.filters = tags;

          if (!this.rpn &&
          this.booklet.canEdit && this.booklet.criterias.length > 0 &&
          this.booklet.monitoredStudents.length > 0) {
            this.service.getObservations(params['id']).subscribe(observations => {
              this.observations = observations;
              this.updateProgression();
              this.chart = new Chart(this.chartRef.nativeElement, {
                type: 'doughnut',
                data: {
                  datasets: [
                    {
                      data: this.scale.levels.map(l => this.observations.filter(o => o.state === l.code).length),
                      backgroundColor: this.scale.levels.map(l => l.color),
                      hoverBackgroundColor: this.scale.levels.map(l => l.color)
                    }
                  ],
                  labels: this.scale.levels.map(l => l.label)
                },
                options: {
                  cutoutPercentage: 70,
                  animation: {
                      animateScale: true,
                      animateRotate: true
                  },
                  legend: {
                    display: true,
                    position: 'right'
                }
              }
              });
            });
          }
        },
        error: err=>{
          this.router.navigate(['/unauthorized']);
        }
      });
    });
  }
  clone() {
    const newBooklet = this.booklet;
    if (this.booklet.canEdit) {
      newBooklet.title = this.booklet.title;
    }
    newBooklet.progression = 0;
    this.service.clone(newBooklet).subscribe(res => {
      this.router.navigate(['/folios/personnels/', res.id, 'edition']);
      this.toastService.success('Folio créé');
    });
  }
  sendbymail() {
    this.service.getSecretLink(this.booklet.id).subscribe(secret => {
      const subject = encodeURIComponent(`Partage de ${(this.rpn ? 'modèle de folio' : 'folio')} RPN sur iClasse - ${this.booklet.title}`);
      const body = encodeURIComponent(`Voici le ${(this.rpn ? 'modèle de folio' : 'folio')} RPN sur iClasse ${this.booklet.title} accessible ici ${document.location.protocol}//${document.location.host}/folios/secret/${secret} .`);
      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    });
  }

  getDocumentLocation() {
    return document.location.href;
  }

  copied(e: any) {
    if (e.isSuccess) {
      this.toastService.info('Copié !');
    }
  }
  applyCriteriaFiltering() {
    if (this.anyFilterActivated()) {
      const activatedFilter = [];
      this.filters.forEach(cat => {
        if (cat.filter(f => f.selected).length > 0) {
          activatedFilter.push(cat.filter(f => f.selected));
        }
      });
      this.booklet.criterias.forEach(i => {
        i.filtered = activatedFilter.some(cat => cat.every((f: Filter) => i.tags.indexOf(f.value) < 0))
         ||
         (this.disciplines.filter(d => d.selected).length > 0 &&
         this.disciplines.filter(d => d.selected).every(cat =>  i.categories?.indexOf(cat.category.key) < 0))
         ||
         (this.annees.filter(d => d.selected).length > 0 &&
         this.annees.filter(d => d.selected).every(cat =>  i.categories?.indexOf(cat.category.key) < 0))
         ||
         (this.axesThematiques.filter(d => d.selected).length > 0 &&
         this.axesThematiques.filter(d => d.selected).every(cat =>  i.axesThematiques?.indexOf(cat.value) < 0))
         ;
      });
    } else {
      this.booklet.criterias.forEach(i => {
        i.filtered = false;
      });
    }
    this.filteredCriterias = this.booklet.criterias.filter(c => !c.filtered);
    this.chart.data.datasets[0].data =
    this.scale.levels
    .map(l => this.observations
      .filter(o => o.state === l.code && this.filteredCriterias.map(q => q.id).indexOf(o.criteriaId) >= 0 ).length
    );
    this.chart.update();
    this.updateProgression();
  }
  updateProgression() {
    if (this.booklet.optionsProfessionnelles) {
      this.progression = {
        success: this.observations.filter(o => o.state === 'ok' && this.filteredCriterias.map(q => q.id)
          .indexOf(o.criteriaId) >= 0).length * 100
          / (this.filteredCriterias.length * this.booklet.monitoredStudents.length),
        danger: this.observations.filter(o => o.state === 'nok' && this.filteredCriterias.map(q => q.id)
          .indexOf(o.criteriaId) >= 0).length * 100
          / (this.filteredCriterias.length * this.booklet.monitoredStudents.length),
        info: this.observations.filter(o => o.state === 'unrated' && this.filteredCriterias.map(q => q.id)
          .indexOf(o.criteriaId) >= 0).length * 100
          / (this.filteredCriterias.length * this.booklet.monitoredStudents.length)
      };
    } else {
      this.progression = {
        success: this.observations.filter(o => o.state === 'ok' && this.filteredCriterias.map(q => q.id)
          .indexOf(o.criteriaId) >= 0).length * 100
          / (this.filteredCriterias.length * this.booklet.monitoredStudents.length),
        warning: this.observations.filter(o => o.state === 'nok' && this.filteredCriterias.map(q => q.id)
          .indexOf(o.criteriaId) >= 0).length * 100
          / (this.filteredCriterias.length * this.booklet.monitoredStudents.length),
        secondary: this.observations.filter(o => o.state === 'unrated' && this.filteredCriterias.map(q => q.id)
          .indexOf(o.criteriaId) >= 0).length * 100
          / (this.filteredCriterias.length * this.booklet.monitoredStudents.length)
      };
    }

  }
  anyFilterActivated(): boolean {
    return this.filters.filter(cat => cat.filter(f => f.selected).length > 0).length > 0
    ||
    this.disciplines.filter(d => d.selected).length > 0
    ||
    this.annees.filter(d => d.selected).length > 0
    ||
    this.axesThematiques.filter(d => d.selected).length > 0
    ;
  }

  getReports(student: MonitoredStudent) {
    this.service.getObservations(this.booklet.id).subscribe(observations => {
      this.service.generateStudentReports(this.booklet, this.booklet.monitoredStudents,  observations);
    });
  }
  getCsv(){
    this.service.getCsvExport(this.booklet.id).subscribe(csv => {
      console.log(csv);
    });
  }

  getXlsx(){
    this.service.getExcelExport(this.booklet.id);
  }
  getReport(student: MonitoredStudent) {
    this.service.getObservationsByStudent(this.booklet.id, student.id).subscribe(obs => {
      this.service.generateStudentReport(this.booklet, student,  obs.observations);
    });
  }
  getEvaluation(criteriaId: number, studentId: number): string {
    const relatedObs =  this.observations.filter(o => o.criteriaId === criteriaId && o.monitoredStudentId === studentId);
    if (relatedObs.length > 0) {
      return relatedObs[0].state;
    } else {
      return null;
    }
  }
  getObservation(criteriaId: number, studentId: number): Observation{
    const relatedObs =  this.observations.filter(o => o.criteriaId === criteriaId && o.monitoredStudentId === studentId);
    if (relatedObs.length > 0) {
      return relatedObs[0];
    } else {
      return new Observation();
    }
  }
  print() {
    window.print();
  }
}
