<nemo-basepage [title]="'Critère'">
  <div page-body>
    <div class="row">
      <div class="col-md-12">
        <div class="row d-print-none">
          <div class="col-md-4 mb-1" *ngIf="criteria && booklet">
            <nemo-card [big]="true" [title]="criteria.label" [icon]="booklet.icon" [color]="booklet.color"
              [subtitle]="booklet.title" [tags]="criteria.categories"></nemo-card>
            <div class="my-2 d-grid gap-2">
              <button type="submit" class="btn btn-primary" tooltip="Sauvegarder" tooltipPlacement="top"
                [disabled]="!dirty || saving" (click)="saveObservations()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="saving"></span>
                <span class="bi bi-save-fill" *ngIf="!saving"></span> Sauvegarder</button>
            </div>
            <div class="d-flex my-2">
              <div class="flex-grow-1 d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary" tooltip="Retour"
                  tooltipPlacement="top" [disabled]="dirty" [routerLink]="['../../']"><span class="bi bi-arrow-left"></span> Retour</button>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-none d-lg-block">
            <div class="card text-center">
              <div class="card-body">
                <h6 class="card-subtitle mb-2 text-muted">Progression par évaluation</h6>
                <canvas #lineChart>{{ chart }}</canvas>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-4">
            <div class="card text-center">
              <div class="card-body">
                <h6 class="card-subtitle mb-2 text-muted">Progression générale</h6>
                <nemo-stacked-progress-bar *ngIf="observations" [progression]="progression" [height]="15"></nemo-stacked-progress-bar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul ngbNav #nav="ngbNav" class="nav-tabs nav-fill" [(activeId)]="activeId">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Observations</a>
            <ng-template ngbNavContent>
              <nemo-booklet-observation-actions-toolbar  (markAll)="markAll($event)" (update)="updateAllEvaluation($event)" (comment)="commentAllObservations($event)" (eraseAllComments)="eraseAllComments()" (eraseAllEvaluations)="eraseAllEvaluations()" (eraseAllMarks)="eraseAllMarks()" [scale]="scale" *ngIf="scale"></nemo-booklet-observation-actions-toolbar>
              <h1 class="d-none d-print-inline" *ngIf="criteria && booklet">
                {{booklet.title}}
                <small class="text-muted d-block">{{criteria.label}}</small>
              </h1>
              <ul class="list-group" *ngIf="observations">
                <li class="list-group-item" *ngFor="let obs of observations">
                  <nemo-observation [labelledObservation]="obs" (changed)="updateObservation($event)" [scale]="scale"  [op]="booklet.optionsProfessionnelles" *ngIf="scale" [displayAutoEvaluationIcon]="displayAutoEvaluationIcon"> </nemo-observation>
                </li>
              </ul>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</nemo-basepage>
