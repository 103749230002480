import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferenceDataService } from '@app/core/service';
import { PlanEtudeRomandService } from '@app/core/service/plan-etude-romand.service';
import { Category, PlanEtudeRomandSearchViewModel } from '@app/model';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { NgArrayPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { PlanEtudeRomandDisplayComponent } from '../plan-etude-romand-display/plan-etude-romand-display.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'nemo-plan-etude-romand-selector',
    templateUrl: './plan-etude-romand-selector.component.html',
    styleUrls: ['./plan-etude-romand-selector.component.scss'],
    standalone: true,
    imports: [CommonModule, PlanEtudeRomandDisplayComponent, NgArrayPipesModule,FormsModule, NgObjectPipesModule, ReplacePipe]
})
export class PlanEtudeRomandSelectorComponent implements OnInit {
  @Output() planEtudeRomandSelected = new EventEmitter<PlanEtudeRomandSearchViewModel[]>();
  @Input() multi = false;
  @Input() hasCriteria = false;
  availableCategories : Category[] ;
  categories: Category[] = [];
  disciplines:Category[];
  selectedDiscipline:Category;
  years:Category[];
  selectedYear:Category;
  axes:string[];
  selectedAxe:string;
  term: string = "";
  enabledSearch = true;
  searching=true;
  matchingLearnings :PlanEtudeRomandSearchViewModel[] = [];
  @Input() selectedPers: PlanEtudeRomandSearchViewModel[] = [];

  constructor(private readonly planEtudeRomandService: PlanEtudeRomandService,private readonly referenceDataService: ReferenceDataService){
    this.availableCategories= referenceDataService.getCategories();
    
   
  }
  ngOnInit(): void {
    this.planEtudeRomandService.fetchYears(this.hasCriteria).subscribe(y=>{
      this.years =this.availableCategories.filter(c=>c.group==='Année' && y.indexOf(+c.key)>=0).sort((a,b)=>a.ord>b.ord?0:-1);
      this.searching = false;
    });
  }
  selectYear(year: Category){
    this.selectedYear = year;
    this.searching = true;
    
    this.planEtudeRomandService.fetchDisciplines(this.selectedYear.key,this.hasCriteria).subscribe(perDisciplines=>{
      this.disciplines =this.availableCategories.filter(c=>c.group==='Discipline' && perDisciplines.indexOf(c.key)>=0).sort((a,b)=>a.ord>b.ord?0:-1);
      this.searching = false;
    });
  }
  selectDiscipline(discipline: Category):void {
    this.selectedDiscipline = discipline;
    this.searching = true;
    this.planEtudeRomandService.fetchAxesThematiques(this.selectedDiscipline.key, this.selectedYear.key,this.hasCriteria).subscribe(y=>{
      this.axes=y;
      this.searching = false;
    });
  }
  selectAxe(axe: string){
    this.selectedAxe = axe;
    this.searching = true;
    this.planEtudeRomandService.search(this.selectedDiscipline.key, this.selectedYear.key, this.selectedAxe,this.hasCriteria, '').subscribe(y=>{
      this.matchingLearnings = y;
      this.enabledSearch = y.length>6;
      this.searching = false;
    })
  }
  clearTerm(){
    this.term = '';
    this.search();
  }
  search(){
    this.searching = true;
    this.planEtudeRomandService.search(this.selectedDiscipline.key, this.selectedYear.key,this.selectedAxe,this.hasCriteria,this.term).subscribe(per=>{
      this.matchingLearnings = per;
      this.searching = false;
    });
  }
  select(per:PlanEtudeRomandSearchViewModel):void{
    per.selectedAxe=this.selectedAxe;
    per.selectedYear=this.selectedYear.key;
    per.selectedDiscipline=this.selectedDiscipline.key;
    this.planEtudeRomandSelected.emit([per]);
  }
  multiSelect(per:PlanEtudeRomandSearchViewModel):void{
    this.selectedPers.indexOf(per)>=0 ? this.selectedPers.splice(this.selectedPers.indexOf(per), 1):this.selectedPers.push(per);
  }
  selectAllToggle():void {
    if(this.selectedPers.length > 0 ){
      this.selectedPers.splice(0,this.selectedPers.length);
    }else{
      this.selectedPers.push.apply(this.selectedPers,this.matchingLearnings);
    }
  }
}
